import React, { useState } from 'react';
import helpimg from './assets/worker-safety-helmet-safety-harness-working-crane 2.png';
import plusicon from './assets/plus-circle.svg';
import minus from './assets/minus.svg';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export default function Help() {
  const [openItem, setOpenItem] = useState(null);

  const toggleText = (itemId) => {
    setOpenItem((prevItem) => (prevItem === itemId ? null : itemId));
  };

  const faqs = [
    {
      question: "What types of luxury items do you authenticate?",
      answer: "We specialize in authenticating a wide range of luxury items including watches, handbags, jewelry, and more. Our expert team meticulously verifies the authenticity of each item to provide you with peace of mind."
    },
    {
      question: "How long does the authentication process take?",
      answer: "The authentication process at our center typically takes between 1 to 2 hours per item. This timeframe allows our experts to conduct a thorough examination to ensure the authenticity of your luxury items."
    },
    {
      question: "How much does the authentication service cost?",
      answer: "The cost of our authentication services varies depending on the complexity of the item being authenticated. We offer competitive pricing tailored to ensure thorough verification and accuracy. For specific pricing details or to schedule an appointment, please contact our team directly."
    },
    {
      question: "What should I bring with me for the authentication appointment?",
      answer: "Please bring your item(s) along with any accompanying documentation such as receipts, certificates, or warranties. This helps our experts in providing a thorough assessment."
    },
    {
      question: "What happens if my item is found to be counterfeit?",
      answer: "If your item does not pass our authentication process, we'll inform you promptly and provide detailed reasons for our findings. You may request further consultation or explore alternative options."
    }
  ];

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqs.map(faq => ({
      "@type": "Question",
      "name": faq.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": faq.answer
      }
    }))
  };

  return (
    <div className='px-[24px] max-w-[1440px] mt-[120px] mx-auto md:px-[36px] w-full'>
      <Helmet>
        <title>FAQs | AlessioWagner</title>
        <meta name='description' content='Frequently Asked Questions about AlessioWagner services.' />
        <link rel="canonical" href="/help" />
        <script type="application/ld+json">
          {JSON.stringify(faqSchema)}
        </script>
      </Helmet>
      <div className='flex flex-col'>
        <div className='w-[100px] xl:w-[150px] xl:h-[50px] h-[30px] relative bg-custom-red-20'>
          <h2 className='font-sans text-[28px] font-regular tracking-[3px] absolute text-[#323232] xl:text-[42px] -top-[18px] left-2  xl:-top-7 xl:left-2'>FAQs</h2>
        </div>

        <div className='flex flex-col gap-[50px] w-full fv:flex-row fv:justify-between'>
          <div className='flex flex-col mt-[30px]'>
            <h1 className='text-[24px] md:text-[28px] xl:text-[32px] font-sans text-[#101828] font-medium'>We're here to help</h1>
            <h2 className='text-[12px] md:text-[14px] xl:text-[16px] font-regular text-[#475467] font-sans'>Have questions? we're here to help.</h2>
            <Link to={'/contact'}>
            <button className='w-[172px] h-[44px] rounded-[8px] bg-[#f2f2f2] mt-[20px] text-[16px] font-semibold font-sans text-black shadow-md hover:bg-[#323232] hover:text-white active:opacity-70 transition duration-1000'>Get in touch</button>
            </Link>
          </div>

          <img className='rounded-[10px] w-full md:w-[300px] max-w-[250px] md:max-w-full  mb:w-[250px]' src={helpimg} alt="questionmark" />
        </div>

        <div className='flex flex-col gap-[10px] mt-[40px]'>
          <h1 className='text-[24px] xl:text-[32px] font-sans text-[#101828] font-medium'>Frequently asked questions</h1>
          <h2 className='text-[12px] xl:text-[16px] font-regular text-[#475467] font-sans'>Everything you need to know about the service.</h2>
        </div>

        <div className='flex flex-col mt-[20px]'>
          {faqs.map((faq, index) => (
            <AccordionItem
              key={index}
              title={faq.question}
              isOpen={openItem === index}
              toggleText={() => toggleText(index)}
            >
              {faq.answer}
            </AccordionItem>
          ))}
        </div>

        <div className='w-full h-[250px] xl:h-auto mt-[50px] md:mt-[50px] xl:mb-[40px] mb-[30px] relative overflow-hidden rounded-[15px]'>
          <div className='w-full items-center flex flex-col gap-[30px] bg-custom-red-20 h-full rounded-[15px] xl:py-[64px] py-[30px] px-[30px]'>
            <h2 className='text-[20px] font-sans text-center font-medium tracking-[2px] xl:text-[32px]'>Still have questions?</h2>
            <h3 className='text-[15px] font-sans font-regular text-center xl:text-[20px]'>Can’t find the answer you’re looking for? Please chat to our friendly team.</h3>
            <Link to={'/contact'}>
            <button className='w-[172px] xl:w-[210px] bg-[#f2f2f2] xl:h-[64px] xl:text-[20px] h-[44px] rounded-[8px] shadow-md text-black font-sans text-[16px] font-semibold hover:bg-[#323232] hover:text-white active:opacity-70 transition duration-1000'>Get in touch</button>
            </Link>
            <div className='hidden md:flex absolute w-[400px] h-[400px] bg-custom-gray-10 -left-[10%] -top-5 -z-10 rounded-full'></div>
            <div className='absolute w-[350px] h-[350px] md:w-[500px] md:h-[500px] bg-custom-gray-10 right-[50%] transfrom translate-x-1/2 top-2 -z-10 rounded-full'></div>
            <div className='hidden md:flex absolute w-[400px] h-[400px] bg-custom-gray-10 -right-[10%] -top-5 -z-10 rounded-full'></div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Accordion item component
function AccordionItem({ title, isOpen, toggleText, children }) {
  return (
    <div className='w-full flex flex-col border-b-[1px] border-opacity-20 py-[15px]'>
      <div className='flex justify-between items-start flex-row'>
        <h4 className='text-[17px] xl:text-[20px] font-medium font-sans text-[#323232]'>{title}</h4>
        <img
          src={isOpen ? minus : plusicon}
          alt=""
          className="cursor-pointer"
          onClick={toggleText}
        />
      </div>
      <div className={`overflow-hidden transition-all duration-300 ${isOpen ? 'max-h-[500px] mt-2' : 'max-h-0 mt-0'}`}>
        <h3 className='text-[14px] xl:text-[16px] font-regular text-[#58595B]'>
          {children}
        </h3>
      </div>
    </div>
  );
}
