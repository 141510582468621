import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import swisslog from './assets/Flag-Switzerland.webp';
import franceseclogo from './assets/france1.jpg';
import italylogo from './assets/italy1.png';
import { Helmet } from 'react-helmet-async';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if required fields are empty
    if (formData.name.trim() === '' || formData.email.trim() === '' || formData.message.trim() === '') {
      alert('Please fill out all required fields (Name, Email, Message).');
      return;
    }

    // Handle form submission logic here
    // For demonstration, you can console log or perform a POST request

    console.log('Form submitted with data:', formData);

    // Clear form data after submission (optional)
    setFormData({
      name: '',
      email: '',
      phone: '',
      message: ''
    });
  };

  return (
    <div className='max-w-[1440px] mx-auto md:px-[28px] mt-[100px]'>
      <Helmet>
        <title>Contact Us | AlessioWagner</title>
        <meta name='description' content='Contact us for luxury item authentication services. Get in touch to authenticate your valuable possessions with our expert team.'/>
        <link rel="canonical" href="/contact" />
      </Helmet>
      <div className='flex flex-col w-full pb-[42px] bg-[#926D65] md:flex-row md:py-[50px] relative overflow-hidden md:rounded-[15px] md:mt-[50px]'>
        <div className='flex flex-col items-center md:items-start w-full gap-[24px] md:gap-[56px] xl:px-[56px] px-[24px] md:py-[0px] py-[24px] '>
          <h1 className='font-sans font-medium text-white text-[32px] xl:text-[48px]'>Contact Us</h1>
          <h2 className='text-[15px] xl:text-[16px] font-sans font-regular md:text-start opacity-80 text-white text-center'>
            Ready to take it to the next level? Let's talk about how we can authenticate your luxury items and ensure their authenticity. From rare watches to designer handbags, our expert team provides reliable verification services to give you peace of mind. If you're looking for trustworthy authentication for your valuable possessions, drop us a line. Let's connect and protect your investments.
          </h2>
        </div>
        <div className='absolute w-[300px] h-[300px] bg-custom-pink-40 opacity-10 rounded-full -top-[20px] -right-[100px] xl:left-[10px] xl:top-[10px] md:w-[450px] xl:w-[650px] xl:h-[650px] md:h-[450px] md:top-[100px] md:-left-[50px]'></div>
        <div className='absolute w-[300px] h-[300px] md:hidden bg-custom-pink-40 opacity-10 rounded-full -top-[20px] -left-[100px]'></div>
        <div className="px-[24px] w-full">
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full font-sans border-b-2 border-white bg-transparent p-2 text-white focus:outline-none"
                placeholder="Name"
                required
              />
            </div>
            <div>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full font-sans border-b-2 border-white bg-transparent p-2 text-white focus:outline-none"
                placeholder="Email"
                required
              />
            </div>
            <div>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                className="w-full font-sans border-b-2 border-white bg-transparent p-2 text-white focus:outline-none"
                placeholder="Phone"
              />
            </div>
            <div>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                className="w-full font-sans border-b-2 border-white bg-transparent p-2 text-white focus:outline-none"
                placeholder="Message"
                rows="4"
                required
              />
            </div>
            <div className="text-center">
              <button type="submit" className="bg-white w-[152px] h-[56px] text-[17px] font-sans font-medium text-black rounded-md mt-4 hover:font-semibold active:opacity-80 transition duration-1000">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className='flex px-[24px] flex-col mt-[100px] mb-[100px] gap-[70px] md:flex-row md:w-full md:justify-between'>
        <div className='flex flex-col items-center gap-[20px]'>
          <img className='opacity-80 w-[200px] h-[200px] rounded-[50%] md:h-[150px] md:w-[150px] xl:h-[250px] xl:w-[250px]' src={swisslog} alt="Switzerland" />
          <h1 className='font-sans text-[25px] tracking-[3px] font-semibold text-center md:text-[22px] xl:text-[28px]'>SWITZERLAND</h1>
          <Link to={'/locations'}>
            <button className='bg-custom-pink-50 shadow-md w-[176px] h-[55px] font-sans text-[18px] font-semibold text-center rounded-[10px] hover:bg-[#323232] hover:text-white transition duration-500'>SEE LOCATION</button>
          </Link>
        </div>
        <div className='flex flex-col items-center gap-[20px]'>
          <img className='opacity-90 w-[200px] h-[200px] rounded-[50%] md:h-[150px] md:w-[150px] xl:h-[250px] xl:w-[250px]' src={franceseclogo} alt="France" />
          <h1 className='font-sans text-[25px] tracking-[3px] font-semibold text-center md:text-[22px] xl:text-[28px]'>FRANCE</h1>
          <Link to={'/locations'}>
            <button className='bg-custom-pink-50 shadow-md w-[176px] h-[55px] font-sans text-[18px] font-semibold text-center rounded-[10px] hover:bg-[#323232] hover:text-white transition duration-500'>SEE LOCATION</button>
          </Link>
        </div>
        <div className='flex flex-col items-center gap-[20px]'>
          <img className='opacity-90 w-[200px] h-[200px] rounded-[50%] md:h-[150px] md:w-[150px] xl:h-[250px] xl:w-[250px]' src={italylogo} alt="Italy" />
          <h1 className='font-sans text-[25px] tracking-[3px] font-semibold text-center md:text-[22px] xl:text-[28px]'>ITALY</h1>
          <Link to={'/locations'}>
            <button className='bg-custom-pink-50 shadow-md w-[176px] h-[55px] font-sans text-[18px] font-semibold text-center rounded-[10px] hover:bg-[#323232] hover:text-white transition duration-500'>SEE LOCATION</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
