import React from 'react'
import { Helmet } from 'react-helmet-async'

export default function Errorpage() {
  return (
    <div>
       <Helmet>
        <title>Error Page | AlessioWagner</title>
      </Helmet>
      <section class="bg-white mt-[90px]">
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
            <h1 class="mb-4 text-7xl font-sans tracking-tight font-extrabold lg:text-9xl text-primary-600">404</h1>
            <p class="mb-4 font-sans text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">Something's missing.</p>
            <p class="mb-4 font-sans text-lg font-light text-gray-500 dark:text-gray-400">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>
            <a href="/" class="inline-flex text-grat-900 bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 border-[2px] border-gray-900 font-sans font-medium hover:font-semibold transition duration-900 rounded-lg text-sm px-5 py-2.5 text-center my-4">Back to Homepage</a>
        </div>   
        </div>
      </section>
    </div>
  )
}
