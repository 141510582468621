import React from 'react'
import Swissimg from './assets/Flag-Switzerland.webp'
import francelogo from './assets/france1.jpg'
import italylogo from './assets/italy1.png'
import franceseclogo from './assets/france2.png'
import italyflag from './assets/italy2.png'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

export default function Locations() {
  return (
    <div className='px-[28px] max-w-[1440px] mx-auto md:px-[28px] py-[24px] mt-[100px]'>
      <Helmet>
        <title>Locations | AlessioWagner</title>
        <meta name='description' content='Find our offices in Switzerland, France, and Italy. Contact us for luxury item authentication services.'/>
        <link rel="canonical" href="/locations" />

      </Helmet>

      <div className='w-[200px] xl:w-[280px] xl:mt-[30px] xl:h-[50px] h-[30px] bg-custom-red-20 relative'>
        <h6 className='font-sans text-[#323232] text-[28px] xl:text-[42px] font-medium tracking-[3px] absolute left-1 -top-6'>LOCATIONS</h6>
      </div>

      <div className='hidden md:flex flex-col gap-[50px] w-full mt-[60px]'>

          <div className='flex w-full flex-row items-center gap-[25px]'>

                <div className='flex flex-col w-[calc(100%-220px)] bg-custom-gray-30 rounded-[15px] shadow-md h-[220px] xl:h-[350px] xl:p-[60px] p-[30px] relative overflow-hidden'>
                  <h2 className='text-[#E7816B] text-[25px] xl:text-[42px] tracking-[3px] font-semibold font-sans'>SWITZERLAND</h2>

                  <div className='flex flex-row mt-[40px] gap-[60px]'>
                    <div className='flex flex-col gap-[5px]'>
                      <h2 className='text-[14px] text-[#333136] font-sans font-bold xl:text-[24px]'>AlesioWagner Central Office</h2>
                      <h3 className='text-[14px] text-[#333136] font-sans font-regular xl:text-[24px]'>8001, Bahnhofstrasse 10</h3>
                      <h3 className='text-[14px] text-[#333136] font-sans font-regular xl:text-[24px]'>Zürich</h3>
                    </div>

                    <div className='flex flex-col gap-[5px]'>
                      <h2 className='text-[14px] text-[#333136] font-sans font-bold xl:text-[24px]'>Contact</h2>
                      <h3 className='text-[14px] text-[#333136] font-sans font-regular xl:text-[24px]'>P: +41 44 122 26 24</h3>
                      <h3 className='text-[14px] text-[#333136] font-sans font-regular xl:text-[24px]'>M: contact@alessiowagner.com</h3>
                    </div>
                  </div>
                  <div className='absolute w-[350px] h-[350px] xl:w-[450px] xl:h-[450px] rounded-full bg-custom-gray-10 -z-10 -bottom-[40px] left-[5px]'></div>
                  <div className='absolute w-[350px] h-[350px] xl:w-[450px] xl:h-[450px] rounded-full bg-custom-gray-10 -z-10 -top-[140px] right-[130px] transform translate-x-1/2'></div>

                </div>

                <div className='w-[220px] h-[220px] xl:w-[350px] xl:h-[350px] flex items-center justify-center rounded-[15px] shadow-md bg-custom-gray-30'>
            <div className='w-[180px] h-[180px] xl:w-[250px] xl:h-[250px] rounded-[50%]'>
              <img className='object-cover w-full h-full rounded-[50%] opacity-60' src={Swissimg} alt="Switzerland" />
            </div>
           </div>
          </div>

          <div className='flex w-full flex-row-reverse items-center gap-[25px]'>

                <div className='flex flex-col w-[calc(100%-220px)] bg-custom-gray-30 rounded-[15px] shadow-md h-[220px] xl:h-[350px] xl:p-[60px] p-[30px] relative overflow-hidden'>
                  <h2 className='text-[#E7816B] text-[25px] xl:text-[42px] font-semibold tracking-[3px] font-sans'>FRANCE</h2>

                  <div className='flex flex-row mt-[40px] gap-[60px]'>
                    <div className='flex flex-col gap-[5px]'>
                      <h2 className='text-[14px] text-[#333136] font-sans font-bold xl:text-[24px]'>AlessioWagner France Office</h2>
                      <h3 className='text-[14px] text-[#333136] font-sans font-regular xl:text-[24px]'>75004, 3 Rue de Rivoli</h3>
                      <h3 className='text-[14px] text-[#333136] font-sans font-regular xl:text-[24px]'>Paris</h3>
                    </div>

                    <div className='flex flex-col gap-[5px]'>
                      <h2 className='text-[14px] text-[#333136] font-sans font-bold xl:text-[24px]'>Contact</h2>
                      <h3 className='text-[14px] text-[#333136] font-sans font-regular xl:text-[24px]'>P: +33 1 44 27 52 10</h3>
                      <h3 className='text-[14px] text-[#333136] font-sans font-regular xl:text-[24px]'>M: contact@alessiowagner.com</h3>
                    </div>
                  </div>
                  <div className='absolute w-[350px] h-[350px] xl:w-[450px] xl:h-[450px] rounded-full bg-custom-gray-10 -z-10 -bottom-[40px] left-[5px]'></div>
                  <div className='absolute w-[350px] h-[350px] xl:w-[450px] xl:h-[450px] rounded-full bg-custom-gray-10 -z-10 -top-[140px] right-[130px] transform translate-x-1/2'></div>

                </div>

                <div className='w-[220px] h-[220px] xl:w-[350px] xl:h-[350px] flex items-center justify-center rounded-[15px] shadow-md bg-custom-gray-30'>
            <div className='w-[180px] h-[180px] xl:w-[250px] xl:h-[250px] rounded-[50%]'>
              <img className='object-cover w-full h-full rounded-[50%] opacity-80' src={franceseclogo} alt="France" />
            </div>
           </div>
          </div>

          <div className='flex w-full flex-row items-center gap-[25px]'>

                <div className='flex flex-col w-[calc(100%-220px)] bg-custom-gray-30 rounded-[15px] shadow-md h-[220px] xl:h-[350px] xl:p-[60px] p-[30px] relative overflow-hidden'>
                  <h2 className='text-[#E7816B] text-[25px] xl:text-[42px] font-semibold font-sans tracking-[3px]'>ITALY</h2>

                  <div className='flex flex-row mt-[40px] gap-[60px]'>
                    <div className='flex flex-col gap-[5px]'>
                      <h2 className='text-[14px] text-[#333136] font-sans font-bold xl:text-[24px]'>AlessioWagner Italy Office</h2>
                      <h3 className='text-[14px] text-[#333136] font-sans font-regular xl:text-[24px]'>80132, Via Giuseppe Verdi</h3>
                      <h3 className='text-[14px] text-[#333136] font-sans font-regular xl:text-[24px]'>Napoli NA</h3>
                    </div>

                    <div className='flex flex-col gap-[5px]'>
                      <h2 className='text-[14px] text-[#333136] font-sans font-bold xl:text-[24px]'>Contact</h2>
                      <h3 className='text-[14px] text-[#333136] font-sans font-regular xl:text-[24px]'>P: +39 081 122 4412</h3>
                      <h3 className='text-[14px] text-[#333136] font-sans font-regular xl:text-[24px]'>M: contact@alessiowagner.com</h3>
                    </div>
                  </div>
                  <div className='absolute w-[350px] h-[350px] xl:w-[450px] xl:h-[450px] rounded-full bg-custom-gray-10 -z-10 -bottom-[40px] left-[5px]'></div>
                  <div className='absolute w-[350px] h-[350px] xl:w-[450px] xl:h-[450px] rounded-full bg-custom-gray-10 -z-10 -top-[140px] right-[130px] transform translate-x-1/2'></div>

                </div>

                <div className='w-[220px] h-[220px] xl:w-[350px] xl:h-[350px] flex items-center justify-center rounded-[15px] shadow-md bg-custom-gray-30'>
            <div className='w-[180px] h-[180px] xl:w-[250px] xl:h-[250px] rounded-[50%]'>
              <img className='object-cover w-full h-full rounded-[50%] opacity-70' src={italyflag} alt="Italy" />
            </div>
           </div>
          </div>
          




          <div>

          </div>

          <div>

          </div>

      </div>

      <div className='flex flex-col gap-[60px] w-full mt-[60px] md:hidden'>

        <div className='flex flex-col items-center gap-[25px] py-[40px] w-full rounded-[10px] shadow-md bg-custom-gray-50 relative overflow-hidden'>

          <img className='rounded-[50%] w-[200px] h-[200px] opacity-90' src={Swissimg} alt="Switzerland" />

          <h2 className='text-[32px] font-regular font-sans text-[#E7816B]'>Switzerland</h2>

          <div className='flex flex-col'>
            <h2 className='text-[15px] font-bold font-sans text-center'>AlessioWagner Central Office</h2>
            <h3 className='text-[15px] font-regular font-sans text-center'>8001, Bahnhofstrasse 10</h3>
            <h3 className='text-[15px] font-regular font-sans text-center'>Zürich</h3>
          </div>
          <div className='flex flex-col'>
            <h2 className='text-[15px] font-bold font-sans text-center'>Contact</h2>
            <h3 className='text-[15px] font-regular font-sans text-center'>P: +41 44 122 26 24</h3>
            <h3 className='text-[15px] font-regular font-sans text-center'>M: contact@alessiowagner.com</h3>
          </div>
          <div className='absolute w-[350px] h-[350px] rounded-full bg-custom-gray-30 -z-10 -bottom-[40px] right-1/2 transform translate-x-1/2'></div>
          <div className='absolute w-[350px] h-[350px] rounded-full bg-custom-gray-30 -z-10 -top-[140px] right-[10px] transform translate-x-1/2'></div>

        </div>

        <div className='flex flex-col items-center gap-[25px] py-[40px] w-full rounded-[10px] shadow-md bg-custom-gray-50 relative overflow-hidden'>

          <img className='rounded-[50%] w-[200px] h-[200px] opacity-90' src={francelogo} alt="Switzerland" />

          <h2 className='text-[32px] font-regular font-sans text-[#E7816B]'>France</h2>

          <div className='flex flex-col'>
            <h2 className='text-[15px] font-bold font-sans text-center'>AlessioWagner France Office</h2>
            <h3 className='text-[15px] font-regular font-sans text-center'>75004, 3 Rue de Rivoli</h3>
            <h3 className='text-[15px] font-regular font-sans text-center'>Paris</h3>
          </div>
          <div className='flex flex-col'>
            <h2 className='text-[15px] font-bold font-sans text-center'>Contact</h2>
            <h3 className='text-[15px] font-regular font-sans text-center'>P: +33 1 44 27 52 10</h3>
            <h3 className='text-[15px] font-regular font-sans text-center'>M: contact@alessiowagner.com</h3>
          </div>
          <div className='absolute w-[350px] h-[350px] rounded-full bg-custom-gray-30 -z-10 -bottom-[40px] right-1/2 transform translate-x-1/2'></div>
          <div className='absolute w-[350px] h-[350px] rounded-full bg-custom-gray-30 -z-10 -top-[140px] transform -translate-x-1/2 -left-[40px]'></div>


        </div>

        <div className='flex flex-col items-center gap-[25px] py-[40px] w-full rounded-[10px] shadow-md bg-custom-gray-50 relative overflow-hidden'>

          <img className='rounded-[50%] w-[200px] h-[200px] opacity-90' src={italylogo} alt="Italy" />

          <h2 className='text-[32px] font-regular font-sans text-[#E7816B]'>Italy</h2>

          <div className='flex flex-col'>
            <h2 className='text-[15px] font-bold font-sans text-center'>AlessioWagner Italy Office</h2>
            <h3 className='text-[15px] font-regular font-sans text-center'>80132 ,Via Giuseppe Verdi</h3>
            <h3 className='text-[15px] font-regular font-sans text-center'>Napoli NA</h3>
          </div>
          <div className='flex flex-col'>
            <h2 className='text-[15px] font-bold font-sans text-center'>Contact</h2>
            <h3 className='text-[15px] font-regular font-sans text-center'>P: +39 081 122 4412</h3>
            <h3 className='text-[15px] font-regular font-sans text-center'>M: contact@alessiowagner.com</h3>
          </div>
          <div className='absolute w-[350px] h-[350px] rounded-full bg-custom-gray-30 -z-10 -bottom-[40px] right-1/2 transform translate-x-1/2'></div>
          <div className='absolute w-[350px] h-[350px] rounded-full bg-custom-gray-30 -z-10 -top-[140px] right-[10px] transform translate-x-1/2'></div>

        </div>


      </div>

      <div className='w-full h-[250px] xl:h-auto mt-[50px] md:mt-[0px] xl:mb-[40px] mb-[30px] relative overflow-hidden rounded-[15px]'> 
          <div className='w-full items-center flex flex-col gap-[30px] bg-custom-red-20 h-full rounded-[15px] xl:py-[64px] py-[30px] px-[30px]'>
           <h2 className='text-[20px] font-sans text-center font-medium xl:text-[32px]'>Still have questions?</h2>
           <h3 className='text-[15px] font-sans font-regular text-center xl:text-[20px]'>Can’t find the answer you’re looking for? Please chat to our friendly team.</h3>
           <Link to={'/contact'}>
           <button className='w-[172px] xl:w-[210px] bg-[#f2f2f2] xl:h-[64px] xl:text-[20px] h-[44px] rounded-[8px] shadow-md text-black font-sans text-[16px] font-semibold hover:bg-[#323232] hover:text-white hover:bg-[#323232] hover:text-white transition duration-1000 active:opacity-70'>Get in touch</button>
           </Link>
           <div className='hidden md:flex absolute w-[400px] h-[400px] bg-custom-gray-10 -left-[10%] -top-5 -z-10 rounded-full'></div>
           <div className='absolute w-[350px] h-[350px] md:w-[500px] md:h-[500px] bg-custom-gray-10 right-[50%] transfrom translate-x-1/2 top-2 -z-10 rounded-full'></div>
           <div className='hidden md:flex absolute w-[400px] h-[400px] bg-custom-gray-10 -right-[10%] -top-5 -z-10 rounded-full'></div>
          </div>
        </div>

    </div>
  )
}
