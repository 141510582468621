import React, { useEffect, useRef } from 'react';

const AnimatedDivs = () => {
  const containerRef1 = useRef(null);
  const containerRef2 = useRef(null);

  useEffect(() => {
    const scroll = (container, direction) => {
      const scrollSpeed = 1;
      let containerWidth = container.scrollWidth;
      let scrollAmount = 0;

      const animateScroll = () => {
        if (direction === 'leftToRight') {
          if (scrollAmount >= containerWidth / 2) {
            scrollAmount = 0;
          } else {
            scrollAmount += scrollSpeed;
          }
          container.scrollLeft = scrollAmount;
        } else if (direction === 'rightToLeft') {
          if (scrollAmount <= 0) {
            scrollAmount = containerWidth / 2;
          } else {
            scrollAmount -= scrollSpeed;
          }
          container.scrollLeft = scrollAmount;
        }
        requestAnimationFrame(animateScroll);
      };

      animateScroll();
    };

    if (containerRef1.current) {
      scroll(containerRef1.current, 'leftToRight');
    }

    if (containerRef2.current) {
      scroll(containerRef2.current, 'rightToLeft');
    }
  }, []);

  return (
    <div className='flex flex-col'>
      <div className='overflow-hidden w-full mt-[80px]' ref={containerRef1}>
        <div className='flex whitespace-nowrap'>
          <div className='flex items-center gap-[15px]'>
          <div style={{backgroundSize:"85%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-jager bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"85%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-breitling bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"85%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-zenith bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"110%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-rolex bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"90%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-patek bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"80%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-omega bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"70%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-chanel bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"80%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-tag bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"100%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-ap bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"90%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  ml-[15px] bg-vacheron bg-center bg-cover bg-no-repeat rounded-full'></div> 
          </div>

          <div className='flex items-center gap-[15px]'>
          
          <div style={{backgroundSize:"85%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-jager bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"85%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-breitling bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"85%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-zenith bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"110%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-rolex bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"90%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-patek bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"80%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-omega bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"70%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-chanel bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"80%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-tag bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"100%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-ap bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"90%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  ml-[15px] bg-vacheron bg-center bg-cover bg-no-repeat rounded-full'></div>
          </div>
        </div>
      </div>

      <div className='overflow-hidden w-full' ref={containerRef2}>
        <div className='flex whitespace-nowrap'>
          <div className='flex items-center gap-[15px]'>
          <div style={{backgroundSize:"85%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px] ml-[15px] bg-cartier bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"85%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-hamilton bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"70%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-montblanc bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"70%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  ml-[15px] bg-hublot bg-center bg-cover bg-no-repeat rounded-full'></div> 
          <div style={{backgroundSize:"90%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-breguet bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"90%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-rm bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"90%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  ml-[15px] bg-gp bg-center bg-cover bg-no-repeat rounded-full'></div> 
          <div style={{backgroundSize:"85%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-iwc bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"60%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-lv bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"85%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-gucci bg-center bg-cover bg-no-repeat rounded-full'></div>
          
          </div>

          <div className='flex items-center gap-[15px]'>
            
          <div style={{backgroundSize:"85%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-cartier bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"85%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-hamilton bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"70%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-montblanc bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"70%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  ml-[15px] bg-hublot bg-center bg-cover bg-no-repeat rounded-full'></div> 
          <div style={{backgroundSize:"90%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-breguet bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"90%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-rm bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"90%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  ml-[15px] bg-gp bg-center bg-cover bg-no-repeat rounded-full'></div> 
          <div style={{backgroundSize:"85%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-iwc bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"60%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-lv bg-center bg-cover bg-no-repeat rounded-full'></div>
          <div style={{backgroundSize:"85%"}} className='w-[100px] h-[100px] md:w-[150px] md:h-[150px]  w ml-[15px] bg-gucci bg-center bg-cover bg-no-repeat rounded-full'></div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimatedDivs;
