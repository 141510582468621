import Header from "./Header";
import Footer from "./Footer";
import Mainpage from "./mainpage";
import Aboutus from "./Aboutus";
import Locations  from "./locations";
import Authenticator from "./Authenticator";
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import Help from "./help";
import ScrollToTop from "./scrolltotop";
import Contact from "./contact";
import Errorpage from "./errorpage";
function App() {
  
  return (
    <>
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Mainpage/>}/>
        <Route path="/aboutus" element={<Aboutus/>}/>
        <Route path="/locations"element={<Locations/>} />
        <Route path="/verify-your-item" element={<Authenticator />} />
        <Route path="/help" element={<Help />}/>
        <Route path="/contact" element={<Contact />}/>
        <Route path="*" element={<Errorpage />} />
      </Routes>
      <Footer />
    </Router>
    </>
    
  );
}

export default App;
