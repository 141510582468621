import React from "react";
import footerimg from './assets/Group 8566.svg'
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="w-full flex flex-col items-center bg-[#1A1818] py-[40px] px-[12px] md:px-[36px]">
      <div className="w-full xl:max-w-[1440px] flex flex-col gap-[24px] items-center md:justify-between md:flex-row xl:px-[28px]">
        <Link to={'/'}>
        <img className="w-[210px] lg:w-[250px]" src={footerimg} alt="" />
        </Link>

        <div className="xl:max-w-[1440px] w-full h-[1px] bg-white opacity-30 md:hidden xl:px-[28px]"></div>

        <div className="flex flex-col md:flex-row md:gap-[15px] gap-[5px] items-center xl:max-w-[1280px]">
          <a href="/verify-your-item" className="font-sans text-[14px] text-white font-regular opacity-80 hover:opacity-100 cursor-pointer">VERIFY YOUR ITEM</a>
          <a href="/help" className="font-sans text-[14px] text-white font-regular opacity-80 hover:opacity-100 cursor-pointer " >HELP</a>
          <a href="/locations" className="font-sans text-[14px] text-white font-regular opacity-80 hover:opacity-100 cursor-pointer " >LOCATIONS</a>
          <a href="/aboutus" className="font-sans text-[14px] text-white font-regular opacity-80 hover:opacity-100 cursor-pointer ">ABOUT US</a>
          <a href="/contact" className="font-sans text-[14px] text-white font-regular opacity-80 hover:opacity-100 cursor-pointer ">CONTACT</a>
        </div>
      </div>

      <div className="hidden md:flex w-full h-[1px] bg-white mt-[30px] opacity-30 xl:max-w-[1380px]"></div>

      <div className="flex md:w-full flex-col gap-[20px]  md:justify-between mt-[30px] md:flex-row xl:max-w-[1440px] xl:px-[28px]">
        <div className="text-white opacity-80">
          <h2 className="font-sans text-[16px] font-bold">AlessioWagner Switzerland center</h2>
          <h2 className="font-sans text-[16px] font-regular md:text-start text-center">8001, Bahnhofstrasse 10</h2>
          <h2 className="font-sans text-[16px] font-regular md:text-start text-center">Switzerland, Zürich</h2>
        </div>
        <div className="hidden md:flex flex-col text-white opacity-80">
          <h2 className="font-sans text-[16px] font-bold">AlessioWagner France center</h2>
          <h2 className="font-sans text-[16px] font-regular md:text-start text-center">75004, 3 Rue de Rivoli</h2>
          <h2 className="font-sans text-[16px] font-regular md:text-start text-center">France, Paris</h2>
        </div>
        <div className="hidden md:flex flex-col text-white opacity-80">
          <h2 className="font-sans text-[16px] font-bold">AlessioWagner Italy center</h2>
          <h2 className="font-sans text-[16px] font-regular md:text-start text-center">80132, Via Giuseppe Verdi</h2>
          <h2 className="font-sans text-[16px] font-regular md:text-start text-center">Italy, Napoli NA</h2>
        </div>

        <div className="text-white opacity-80">
        <h2 className="font-sans text-[16px] font-bold">Contact Us (central office)</h2>
        <h2 className="font-sans text-[16px] font-regular md:text-start text-center">contact@alessiowagner.com</h2>
        <h2 className="font-sans text-[16px] font-regular md:text-start text-center">+41 44 122 26 24</h2>
          
        </div>

      </div>

    </div>
  );
}

export default Footer;

