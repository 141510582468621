import React from 'react';
import './app.css';
import serviceimg from './assets/Ellipse 8.png';
import authimg from './assets/Ellipse 40 1.svg';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import AnimatedDivs from './animateddivs';
import { useEffect } from 'react';
import { useState } from 'react';


export default function Mainpage() {


  const [isHighResLoaded, setIsHighResLoaded] = useState(false);

  useEffect(() => {
    const highResImage = new Image();
    highResImage.src = './assets/Rectangle 5 (2).svg';  // Path to your high-res image
    
    highResImage.onload = () => {
      setIsHighResLoaded(true); // When the high-res image is loaded, update the state
    };
  }, []);

  return (
    <div className='px-[14px] max-w-[1440px] mx-auto md:px-[28px] mt-[100px] pb-[30px]'>
      <Helmet>
        <title>Luxury Item Authentication | AlessioWagner</title>
        <meta name='description' content='Verify the authenticity of your luxury items with our professional services at AlessioWagner. We provide thorough inspections and authenticity certificates for watches, jewelry, and other luxury goods.' />
        <meta name='keywords' content='Alessio Wagner, alessio wagner, luxury item verification, luxury item authentication, watch authentication, AlessioWagner, Luxury item experts'/>
        <link rel="canonical" href="/" />
      </Helmet>

      <div className='w-full flex flex-col xl:mt-[20px]'>
        <div className={`back shadow-sm h-screen bg-cover bg-center bg-no-repeat px-[12px] ${
          isHighResLoaded ? 'high-res' : ''
        } `}>
          <div className="flex flex-col items-center justify-between h-full xl:py-[60px] py-[35px]">
            <h2 className='text-[22px] tracking-[2px] font-sans text-white font-bold text-center md:text-[35px] xl:text-[42px] xl:max-w-[650px] md:max-w-[450px]'>
              Precision in every detail, authenticity assured.
            </h2>
            <Link to={'/aboutus'}>
              <button className='font-sans tracking-[1px] h-[42px] w-[135px] text-black rounded-[5px] bg-white text-[18px] font-medium hover:font-bold'>
                About us
              </button>
            </Link>
          </div>
        </div>

        <div className='flex flex-col gap-[80px] mt-[80px] xl:mt-[150px] md:flex-row xl:justify-between'>
          <div>
            <div className='w-[300px] md:w-[250px] xl:w-[450px] md:h-[40px] xl:h-[47px] h-[45px] bg-custom-gray-50 bg-opacity-30 shadow-md relative z-5'>
              <h2 className='absolute tracking-[4px] md:text-[25px] xl:max-w-[10px] xl:text-[32px] z-6 text-black text-[32px] font-sans font-semibold md:-top-4 xl:-top-6 -top-7 left-2'>
                QUALITY ASSURANCE
              </h2>
            </div>
            <p className='text-[16px] leading-[32px] xl:max-w-[500px] xl:text-[21px] text-black font-regular mt-[40px] font-sans'>
              We uphold an unwavering commitment to quality assurance. Every product that bears our name undergoes rigorous scrutiny to meet the highest standards of craftsmanship and reliability.
            </p>
          </div>

          <div className='hidden md:flex w-[1px] h-[200px] xl:h-[230px] opacity-20 bg-black'></div>

          <div>
            <div className='w-[300px] xl:w-[450px] xl:h-[47px] md:w-[250px] md:h-[40px] h-[45px] bg-custom-gray-50 bg-opacity-30 shadow-md relative z-5'>
              <h2 className='absolute uppercase xl:text-[32px] md:text-[25px] z-10 text-black text-[32px] font-sans font-semibold tracking-[5px] md:-top-4 xl:-top-6 -top-7 left-2'>
                Ensuring Satisfaction
              </h2>
            </div>
            <p className='text-[16px] leading-[32px] xl:max-w-[500px] xl:text-[21px] text-black font-regular mt-[40px] font-sans'>
            Your satisfaction is at the heart of everything we do. We strive to exceed your expectations by offering superior luxury authentication services and exceptional customer care that enhance your overall experience with us.            </p>
          </div>
        </div>

        <AnimatedDivs />
        


        <div className='flex flex-col gap-[50px] mt-[60px] md:flex-row md:justify-between gap-[120px] xl:mt-[120px]'>
          <div className='flex flex-col rounded-[15px] bg-custom-gray shadow-md pb-[30px] md:py-[12px] md:px-[12px] gap-[20px] md:h-auto xl:h-auto lg:w-[650px] items-center md:border md:rounded-[8px] md:shadow-md md:py-[35px]'>
            <img className='w-[175px] xl:w-[200px] xl:h-[200px] md:w-[150px] md:h-[150px]' src={serviceimg} alt="Luxury Item Service Center" />
            <h1 className='font-sans text-[24px] tracking-[5px] xl:text-[22px] max-w-[300px] md:max-w-[340px] xl:max-w-[340px] md:text-[14px] font-medium text-center text-black pb-[20px]'>
              Bring Your Items
            </h1>
            <p className='text-[14px] xl:text-[14px] md:text-[10px] md:max-w-[350px] xl:max-w-[400px] xl:text-center md:text-start max-w-[300px] text-black font-sans font-regular text-center leading-[22px]'>
              We're here to ensure your items receive the attention they deserve. Bring your products to our dedicated service center, where our expert team stands ready to provide meticulous care and solutions tailored to your needs. Whether it's repairs, maintenance, or consultations, trust us to deliver excellence every step of the way.
            </p>
          </div>


          <div className='flex flex-col rounded-[15px] bg-custom-gray shadow-md py-[30px] md:px-[12px] gap-[20px] items-center md:border md:rounded-[8px] md:shadow-md lg:w-[650px] md:py-[35px]'>
            <img className='w-[175px] xl:w-[200px] xl:h-[200px] md:w-[150px] md:h-[150px]' src={authimg} alt="Authenticity Certificate" />
            <h1 className='font-sans xl:text-[22px]  md:text-[14px] text-[24px] font-medium tracking-[5px] text-center text-black pt-[10px] pb-[25px]'>
              Authenticity Guarantee
            </h1>
            <p className='text-[14px] xl:text-[14px] md:max-w-[350px] xl:max-w-[440px] xl:text-center max-w-[300px] md:text-start md:text-[10px] text-black font-sans font-regular leading-[22px] text-center'>
            After meticulous inspection, your item qualifies for our authenticity certificate. It assures genuine quality and peace of mind, featuring a unique 8-symbol code for verification on our website. Trust our team for thorough and reliable authentication.            </p>
          </div>
        </div>

        <div className='w-full h-[250px] xl:h-auto mt-[50px] md:mt-[60px] xl:mb-[60px] mb-[15px] relative overflow-hidden rounded-[15px] xl:mt-[100px]'> 
          <div className='w-full items-center flex flex-col gap-[30px] bg-custom-gray-20 h-full rounded-[15px] xl:py-[64px] py-[30px] px-[30px]'>
           <h2 className='text-[20px] font-sans text-center font-semibold xl:text-[32px]'>Do you have questions?</h2>
           <h3 className='text-[15px] font-sans font-regular text-center xl:text-[20px]'>Can’t find the answer you’re looking for? Please chat to our friendly team.</h3>
           <Link to={'/contact'}>
           <button className='w-[172px] xl:w-[210px] bg-[#f2f2f2] xl:h-[64px] xl:text-[20px] h-[44px] rounded-[8px] shadow-md text-black font-sans text-[16px] font-semibold hover:bg-[#323232] hover:text-white active:opacity-70 transition duration-1000'>Get in touch</button>
           </Link>
           <div className='hidden md:flex absolute w-[400px] h-[400px] bg-custom-gray-10 -left-[10%] -top-5 -z-10 rounded-full'></div>
           <div className='absolute w-[350px] h-[350px] md:w-[500px] md:h-[500px] bg-custom-gray-10 right-[50%] transfrom translate-x-1/2 top-2 -z-10 rounded-full'></div>
           <div className='hidden md:flex absolute w-[400px] h-[400px] bg-custom-gray-10 -right-[10%] -top-5 -z-10 rounded-full'></div>
          </div>

        </div>

      </div>
    </div>
  )
}
