import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import headerimg from './assets/Group 8563 (2).svg';
import './app.css';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollDirection, setScrollDirection] = useState("up");
  const location = useLocation();

  useEffect(() => {
    let lastScrollY = window.scrollY;
    const threshold = document.documentElement.scrollHeight * 0.05;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;

      if (scrollY === 0) {
        setScrollDirection("up");
      } else if (scrollY > threshold) {
        if (scrollY > lastScrollY) {
          setScrollDirection("down");
        } else if (scrollY < lastScrollY) {
          setScrollDirection("up");
        }
      }
      lastScrollY = scrollY;
    };

    window.addEventListener("scroll", updateScrollDirection);

    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, []);

  useEffect(() => {
    setScrollDirection("up");
  }, [location]);

  const onBurgerClick = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeMenu = () => setIsOpen(false);

  return (
    <div className={`fixed bg-white top-0 left-0 w-full z-50 transition-transform duration-300 ${scrollDirection === "down" ? "-translate-y-full" : "translate-y-0"}`}>
      <div className="flex flex-row max-w-[1440px] mx-auto h-[80px] px-[14px] relative md:px-[28px]">
        <div className="flex w-full items-center justify-between">
          <Link to={'/'}>
            <img className="w-[220px] cursor-pointer xl:w-[280px]" src={headerimg} alt="Company Logo" />
          </Link>
          <nav className="hidden md:flex space-x-4">
            <Link to="/verify-your-item" className="text-black text-[15px] font-sans font-regular cursor-pointer hover:font-medium">
              Verify Your item
            </Link>
            <Link to="/help" className="text-black text-[15px] font-sans font-regular cursor-pointer hover:font-medium">
              Help
            </Link>
            <Link to="/locations" className="text-black text-[15px] font-sans font-regular cursor-pointer hover:font-medium">
              Locations
            </Link>
            <Link to="/aboutus" className="text-black text-[15px] font-sans font-regular cursor-pointer hover:font-medium">
              About us
            </Link>
            <Link to="/contact" className="text-black text-[15px] font-sans font-regular cursor-pointer hover:font-medium">
              Contact
            </Link>
          </nav>

          {/* Mobile burger menu */}
          <div
            onClick={onBurgerClick}
            className="flex flex-col mt-[10px] gap-[4px] md:hidden cursor-pointer"
          >
            <div
              className={`w-[24px] h-[4px] bg-[#1D1C1E] transition-transform duration-300 ${isOpen ? "rotate-45 translate-y-2" : "rotate-0 translate-y-0"}`}
            ></div>
            <div
              className={`w-[24px] h-[4px] bg-[#1D1C1E] transition-opacity duration-300 ${isOpen ? "opacity-0" : "opacity-100"}`}
            ></div>
            <div
              className={`w-[24px] h-[4px] bg-[#1D1C1E] transition-transform duration-300 ${isOpen ? "-rotate-45 -translate-y-2" : "rotate-0 translate-y-0"}`}
            ></div>
          </div>

          {/* Dropdown for mobile */}
          <nav
            className={`fixed flex flex-col gap-[25px] pt-[30px] top-[80px] left-0 w-full h-[calc(100vh-80px)] bg-black text-white transition-opacity duration-300 overflow-hidden ${isOpen ? "opacity-100" : "opacity-0 pointer-events-none"}`}
          >
            <Link
              onClick={closeMenu}
              to="/verify-your-item"
              className="block uppercase text-white text-center font-sans text-[25px] hover:text-white mb-2 transition-opacity duration-300"
            >
              Verify Your Item
            </Link>
            <Link
              onClick={closeMenu}
              to="/help"
              className="block uppercase text-white font-sans text-center text-[25px] hover:text-white mb-2 transition-opacity duration-300"
            >
              Help
            </Link>
            <Link
              onClick={closeMenu}
              to="/locations"
              className="block uppercase text-white font-sans text-center text-[25px] hover:text-white mb-2 transition-opacity duration-300"
            >
              Locations
            </Link>
            <Link
              onClick={closeMenu}
              to="/aboutus"
              className="block uppercase text-white font-sans text-center text-[25px] hover:text-white mb-2 transition-opacity duration-300"
            >
              About us
            </Link>
            <Link
              onClick={closeMenu}
              to="/contact"
              className="block uppercase text-white font-sans text-center text-[25px] hover:text-white mb-2 transition-opacity duration-300"
            >
              Contact
            </Link>

            <div className="w-full flex flex-col py-[30px] items-center bg-white">
              <div className="w-full xl:max-w-[1440px] flex flex-col gap-[24px] items-center">
                <Link to={'/'}>
                  <img className="w-[206px]" src={headerimg} alt="" />
                </Link>

                <div className="xl:max-w-[1440px] w-[90%] h-[1px] bg-black opacity-30 md:hidden xl:px-[28px]"></div>
              </div>

              <div className="flex md:w-full flex-col gap-[20px] md:justify-between mt-[30px]">
                <div className="text-black opacity-80">
                  <h2 className="font-sans text-[16px] font-bold">AlessioWagner Central office</h2>
                  <h2 className="font-sans text-[16px] font-regular md:text-start text-center">8001, Bahnhofstrasse 10</h2>
                  <h2 className="font-sans text-[16px] font-regular md:text-start text-center">Switzerland, Zürich</h2>
                </div>

                <div className="text-black opacity-80 pb-[40px]">
                  <h2 className="font-sans text-[16px] font-bold">Contact Us (central office)</h2>
                  <h2 className="font-sans text-[16px] font-regular md:text-start text-center">contact@alessiowagner.com</h2>
                  <h2 className="font-sans text-[16px] font-regular md:text-start text-center">+41 44 122 26 24</h2>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Header;
