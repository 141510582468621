import React from 'react'
import brandlogo from './assets/Group 8568.svg'
import ourteamlogo from './assets/Rectangle 102 (1).svg'
import franceseclogo from './assets/france1.jpg'
import italylogo from './assets/italy1.png'
import swisslog from './assets/Flag-Switzerland.webp'
import authprocessimg from './assets/Rectangle 29 (1).svg'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

export default function Aboutus() {
  return (
    <div className='max-w-[1440px] mx-auto px-[28px] md:px-[28px] pb-[70px] mt-[120px]'>
      <Helmet>
        <title>About Us | AlessioWagner</title>
        <meta name='description' content="Learn about AlessioWanger's luxury item authentication services and our dedicated team."/>
        <link rel="canonical" href="/aboutus" />
      </Helmet>

      <div className='mt-[50px] flex flex-col gap-[80px] xl:gap-[150px] items-center'>

        <div className='flex flex-col max-w-[550px] md:max-w-full  w-full items-center md:flex-row-reverse'>
          <img loading='lazy' className='md:w-[250px] w-[350px] mb-[10px] md:mb-0 md:ml-[55px] xl:w-[450px]' src={brandlogo} alt="Brand Logo" />
          <div className='flex flex-col gap-[15px] w-full shadow-md rounded-b-[10px] md:rounded-l-[10px] md:rounded-r-[0px] px-[25px] mm:px-[35px] xl:px-[50px] xl:pb-[100px] py-[25px] bg-custom-red-20'>
            <h1 className='font-sans text-center text-[22px] md:text-[28px] mm:text-[42px] md:text-start font-regular tracking-[4px] xl:text-[62px]'>About us</h1>
            <h2 className='font-sans text-[12px] md:text-[15px] mm:text-[16px] font-regular leading-[28px] xl:text-[18px] md:max-w-[350px] mm:max-w-[450px] xl:max-w-[550px]'>Founded in 2015, we specialize in luxury item authentication. Trusted globally by collectors, sellers, and enthusiasts, we ensure the authenticity of luxury products through rigorous verification processes. Our commitment since 2015 drives us to innovate continuously, offering unparalleled peace of mind to our clients.</h2>
          </div>
        </div>

        <div className="flex max-w-[550px] md:max-w-full flex-col md:w-full md:flex-row h-full">
         <img loading='lazy' className="w-full md:w-auto h-full md:h-auto object-cover xl:w-[1000px] md:rounded-l-[10px] " src={ourteamlogo} alt="Our team logo" />
         <div className="flex flex-col gap-[15px] w-full md: shadow-md rounded-b-[10px] px-[25px] py-[25px] xl:px-[100px] xl:pb-[60px] bg-custom-red-20 md:px-[50px]">
            <h1 className="font-sans text-center text-[22px] md:text-[28px] md:text-start font-regular tracking-[4px] mm:text-[42px] xl:text-[62px]">Our team</h1>
            <h2 className="font-sans text-[12px] mm:text-[16px] leading-[28px] md:text-[15px] font-regular xl:text-[18px] md:max-w-[450px] mm:max-w-[550px] xl:max-w-[650px]">
               Our team is composed of passionate experts dedicated to the art of luxury item authentication. Each member brings unique expertise and a deep understanding of the luxury market, ensuring every authentication is handled with precision and care. With backgrounds ranging from luxury retail to forensic analysis, our team combines knowledge and experience to deliver trusted results. We are committed to upholding the highest standards of integrity and professionalism, earning the confidence of our clients worldwide.
             </h2>
          </div>
          </div>


        <div className='flex flex-col gap-[70px] md:flex-row md:w-full md:justify-between'>

          <div className='flex flex-col items-center gap-[20px]'>
              <img className='opacity-80 w-[200px] h-[200px] rounded-[50%] md:h-[150px] md:w-[150px] xl:h-[250px] xl:w-[250px]' src={swisslog} alt="" />
              <h1 className='font-sans text-[25px] font-medium tracking-[3px] text-center md:text-[22px] xl:text-[28px] '>SWITZERLAND</h1>
              <Link to={'/locations'}>
               <button className='bg-custom-pink-50 shadow-md w-[176px] h-[55px] font-sans text-[18px] font-semibold text-center rounded-[10px] hover:bg-[#323232] hover:text-white transition duration-500'>SEE LOCATION</button>
              </Link>
          </div>

          <div className='flex flex-col items-center gap-[20px]'>
              <img className='opacity-90 w-[200px] h-[200px] rounded-[50%] md:h-[150px] md:w-[150px] xl:h-[250px] xl:w-[250px]' src={franceseclogo} alt="" />
              <h1 className='font-sans text-[25px] font-medium tracking-[3px] text-center md:text-[22px] xl:text-[28px]'>FRANCE</h1>
              <Link to={'/locations'}>
              <button className='bg-custom-pink-50 shadow-md w-[176px] h-[55px] font-sans text-[18px] font-semibold text-center rounded-[10px] hover:bg-[#323232] hover:text-white transition duration-500'>SEE LOCATION</button>
              </Link>
          </div>

          <div className='flex flex-col items-center gap-[20px]'>
              <img className='opacity-90 w-[200px] h-[200px] rounded-[50%] md:h-[150px] md:w-[150px] xl:h-[250px] xl:w-[250px]' src={italylogo} alt="" />
              <h1 className='font-sans text-[25px] font-medium tracking-[3px] text-center md:text-[22px] xl:text-[28px]'>ITALY</h1>
            <Link to={'/locations'}>
              <button className='bg-custom-pink-50 shadow-md w-[176px] h-[55px] font-sans text-[18px] font-semibold text-center rounded-[10px] hover:bg-[#323232] hover:text-white transition duration-500'>SEE LOCATION</button>
              </Link>
          </div>

        </div>


        <div className='flex max-w-[550px] md:max-w-full flex-col md:flex-row'>

          <div className='flex flex-col items-center md:items-start gap-[15px] w-full shadow-md rounded-t-[10px] px-[25px] xl:px-[50px] py-[25px] bg-custom-red-20 md:rounded-r-[0px] md:rounded-l-[10px]'>
            <h1 className='font-sans text-center text-[22px] font-regular tracking-[4px] md:text-[28px] mm:text-[42px] md:text-start xl:text-[62px]'>Authentication</h1>
            <h2 className='font-sans text-[12px] font-regular leading-[32px] md:text-[15px] mm:text-[16px] xl:text-[22px] xl:pr-[100px]'>Every luxury item undergoes a meticulous inspection process, typically completed within 1-2 hours. Upon verification, each item receives a unique 8-symbol code, providing a secure gateway to detailed authentication results on our website. This code ensures transparency and authenticity, allowing clients and stakeholders to access comprehensive verification details promptly. Trusted globally for our rigorous standards and commitment to accuracy.</h2>
            <Link to={'/verify-your-item'}>
            <button className='w-[190px] h-[40px] xl:w-[250px] xl:h-[70px] xl:text-[20px] rounded-[10px] bg-[#3B3333] font-sans text-white font-semibold mm:mt-[50px] text-center text-[15px] xl:mt-[50px] hover:bg-white hover:text-[#323232] tracking-[1.5px] active:opacity-80 hover:border hover:border-[#323232] hover:border-[4px] transition duration-500'>VERIFY YOUR ITEM</button>
            </Link>
          </div>

         <img loading='lazy' className='w-full md:max-w-[350px] mm:max-w-[450px] xl:max-w-[550px] shadow-md rounded-b-[15px] md:object-cover md:rounded-l-[0px] md:rounded-r-[10px]' src={authprocessimg} alt="" />

        </div>


      </div>
    </div>
  )
}
