import React, { useState } from "react";
import testImg from './assets/20234320054313-M278275-0010_modelpage_front_facing_landscape.png';
import { Helmet } from "react-helmet-async";

function Authenticator() {
  const Products = [
    {
      cd: "AA1AA1AA",
      title: "Rolex Daytona Datejust 2142",
      status: "Verified as Authentic",
      brand: "Rolex",
      exactmodel: "Daytona",
      year: "2012",
      serial: "21414fas2",
      model: "214gas",
      img: testImg,
      dateOfManufacture: "2018",
      authnticationDate: "July 4, 2022",
      verificationType: "Certified for careful assembly and rigorous inspection."
    },
    {
      cd: "11111111",
      title: "Rolex GMT Master Pepsi 2644",
      status: "Verified as Authentic",
      brand: "Rolex",
      exactmodel: 'Daytona',
      year: "2009",
      serial: "66214B1",
      model: "164FSS1",
      img: testImg,
      dateOfManufacture: "2018",
      authnticationDate: "July 4, 2022",
      verificationType: "Rigorous inspection"
    },
    {
      cd: "2512jh1",
      title: "Rolex Oyster Perpetual 1718",
      status: "Verified as Authentic",
      brand: "Rolex",
      exactmodel: 'Daytona',
      year: "2019",
      serial: "egd47a6s2",
      model: "5127saf",
      img: testImg,
      dateOfManufacture: "2018",
      authnticationDate: "July 4, 2022",
      verificationType: "Certified for careful assembly and rigorous inspection."
    },
  ];

  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [foundProduct, setFoundProduct] = useState(null);
  const [searched, setSearched] = useState(false);

  const onFindClick = () => {
    setLoading(true);
    setSearched(true);
    setTimeout(() => {
      const found = Products.find((product) => product.cd === searchKey);
      if (found) {
        setFoundProduct(found);
      } else {
        setFoundProduct(null);
      }
      setLoading(false);
    }, 3000);
  };

  const onBackClick = () => {
    setSearchKey("");
    setFoundProduct(null);
    setSearched(false);
    window.scrollTo(0, 0);
  };

  const handleInputChange = (e) => {
    const input = e.target.value.slice(0, 8);
    setSearchKey(input.toUpperCase());
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onFindClick();
    }
  };

  return (
    <div className="flex items-center justify-center w-full mt-[80px]">
      <Helmet>
        <title>Verify Your Luxury Items | AlessioWagner</title>
        <meta name="description" content="Verify the authenticity of your luxury items instantly. Trusted authentication services for luxury watches and handbags." />
        <link rel="canonical" href="/verify-your-item" />
      </Helmet>

      <div className="w-full max-w-[1440px] mx-auto px-[28px] md:px-[28px] flex flex-col">
        <div className="flex flex-col gap-[15px] md:mt-[20px]">
          <div className="w-[240px] md:w-[410px] md:h-[42px] h-[32px] bg-custom-gray-40 shadow-md relative mt-[20px]">
            <h4 className="font-sans text-[23px] md:text-[42px] font-regular tracking-[3px] text-[#323232] absolute md:-top-7 -top-4 uppercase">Verify Your Item</h4>
          </div>
          <h2 className="text-[12px] md:text-[22px] font-sans font-regular text-black">Check the authenticity of your item instantly</h2>
        </div>

        <div className={`flex flex-col w-full ${foundProduct ? 'h-auto' : 'h-[150px]'} items-center mt-[50px]`}>
          {!foundProduct && (
            <div className="flex flex-row w-full gap-[15px] items-center justify-between">
              <input
                type="text"
                placeholder="Enter your unique code"
                className="w-full font-sans rounded-[5px] outline-none h-[40px] shadow-md text-center uppercase placeholder text-base ph:text-[15px]"
                value={searchKey}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                maxLength={8}
              />
              {searchKey.length === 8 && (
                <button
                  onClick={onFindClick}
                  className={`bg-gray-900 hover:bg-gray-700 text-white font-bold py-2 font-sans px-4 rounded ${loading ? "cursor-not-allowed bg-gray-700" : ""}`}
                  disabled={loading}
                >
                  Search
                </button>
              )}
              {searchKey.length !== 8 && (
                <button
                  className="bg-gray-900 font-sans text-white font-bold py-2 px-4 rounded cursor-not-allowed"
                  disabled
                >
                  Search
                </button>
              )}
            </div>
          )}

          {loading && (
            <div className="flex items-center justify-center mt-4">
              <svg
                className="animate-spin h-5 w-5 mr-3 text-black"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="black"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span className="text-black">Searching...</span>
            </div>
          )}

          {!loading && searched && !foundProduct && (
            <p className="mt-4 text-red-500 font-sans">Product not found !!!</p>
          )}

          {foundProduct && (
            <div className="mt-4 flex w-full justify-center items-center mb-[100px]">
              <div className="flex flex-col border bg-custom-gray-01 w-full rounded-[10px] shadow-md p-4">
                <h2 className="text-lg font-semibold font-sans text-center md:text-[22px] md:pt-[30px] xl:text-[32px]">{foundProduct.title}</h2>
                <img className="w-[300px] my-[35px] mx-auto" src={foundProduct.img} alt="" />
                <ul className="text-left mt-[40px] flex flex-col items-center mx-auto">
                  <div className="flex xl:justify-between border-red-500 xl:max-w-[1000px] xl:w-[800px] md:max-w-[550px] flex-col md:flex-row md:gap-[80px]">
                    <div className="flex flex-col">
                      <li className="font-sans font-medium text-[16px] text-black flex items-start w-[250px] mb-2">
                        <span className="list-dot">&#8226;</span>
                        <span className="ml-2 font-sans">Status: {foundProduct.status}</span>
                      </li>
                      <li className="font-sans font-medium text-[16px] text-black flex items-start w-[250px] mb-2">
                        <span className="list-dot ">&#8226;</span>
                        <span className="ml-2 font-sans">Authentication Date: {foundProduct.authnticationDate}</span>
                      </li>
                      <li className="font-sans font-medium text-[16px] text-black flex items-start w-[250px] mb-2">
                        <span className="list-dot">&#8226;</span>
                        <span className="ml-2 font-sans">Brand: {foundProduct.brand}</span>
                      </li>
                      <li className="font-sans font-medium text-[16px] text-black flex items-start w-[250px] mb-2">
                        <span className="list-dot ">&#8226;</span>
                        <span className="ml-2 font-sans">Model: {foundProduct.exactmodel}</span>
                      </li>
                    </div>
                    <div className="">
                      <li className="font-sans font-medium text-[16px] text-black flex items-start w-[250px] mb-2">
                        <span className="list-dot">&#8226;</span>
                        <span className="ml-2 font-sans">Serial Number: {foundProduct.serial}</span>
                      </li>
                      <li className="font-sans font-medium text-[16px] text-black flex items-start w-[250px] mb-2">
                        <span className="list-dot">&#8226;</span>
                        <span className="ml-2 font-sans">Model Number: {foundProduct.model}</span>
                      </li>
                      <li className="font-sans font-medium text-[16px] text-black flex items-start w-[250px] mb-2">
                        <span className="list-dot">&#8226;</span>
                        <span className="ml-2 font-sans">Date of Manufacture: {foundProduct.year}</span>
                      </li>
                      <li className="font-sans font-medium text-[16px] text-black flex items-start w-[250px] mb-2">
                        <span className="list-dot ">&#8226;</span>
                        <span className="ml-2 font-sans">Verification Type: {foundProduct.verificationType}</span>
                      </li>
                    </div>
                  </div>
                </ul>
                <div className="text-center mt-[40px]">
                  <button
                    onClick={onBackClick}
                    className="bg-gray-900 border-gray-900 border-[3px] w-[150px] hover:bg-white hover:text-gray-900 transition duration-700 text-white font-bold py-2 px-4 rounded mt-4"
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          )}

        </div>

        {!foundProduct && (
          <div className="w-full flex flex-col gap-[15px] md:gap-[30px] xl:gap-[45px] items-center bg-custom-gray-40 h-auto rounded-[15px] py-[30px] shadow-md px-[28px] xl:px-[128px] md:px-[36px] mb-[100px] relative overflow-hidden">
            <h2 className="font-sans tracking-[4px] text-[28px] md:text-[36px] xl:text-[42px] font-medium text-[#323232]">STAY SAFE</h2>
            <h3 className="font-sans text-[13px] xl:max-w-[750px] md:text-[16px] xl:text-[22px] font-regular xl:leading-[32px] leading-[25px]">
              In today's market, it's unfortunate that some individuals may attempt to replicate our guarantee certificates. To protect yourself from counterfeit claims, we encourage you to verify the authenticity of your luxury item. Simply enter your unique code here, and our system will confirm whether your item is genuine. Your peace of mind is our priority, so take this important step to ensure the authenticity of your purchase.
            </h3>
            <div className="w-[350px] h-[350px] bg-custom-gray-20 rounded-full absolute left-[10px]"></div>
            <div className="hidden md:flex w-[350px] h-[350px] bg-custom-gray-20 rounded-full absolute -top-[85px] right-[10px]"></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Authenticator;
